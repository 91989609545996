import _ from "lodash/fp";
import { compile } from "path-to-regexp";

const routes = {
  DASHBOARD: "/dashboard",
  DQ_DASHBOARD: "/quality_dashboard",
  CATALOG_DASHBOARD: "/catalog_dashboard",
  GOVERNMENT_DASHBOARD: "/government_dashboard",
  TABLES_VOLUMETRY_DASHBOARD: "/tables_volumetry_dashboard",
  CONTROL_CATALOG_DASHBOARD: "/control_catalog_dashboard",
  CONTROL_GLOSSARY_DASHBOARD: "/control_glossary_dashboard",
  TABLES_EVOLUTION_DASHBOARD: "/tables_evolution_dashboard",
  MANAGEMENT_BEHAVIOR_USERS_DASHBOARD: "/management_behavior_users_dashboard",
  HOME: "/"
};

const linkTo = _.mapValues(compile, routes);

export { routes as default, linkTo };

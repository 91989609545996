import React from "react";
import _ from "lodash/fp";
import { defaultStructureColumns } from "@truedat/dd/selectors";

const defaultColumnsWithoutGroup = _.filter(
  _.negate(_.propEq("name", "group"))
)(defaultStructureColumns);

const defaultColumnsWithoutGroupAndName = _.filter(
  _.negate(_.propEq("name", "name"))
)(defaultColumnsWithoutGroup);

const pathDecorator = path => (
  <span title={_.join(" › ")(path)}>
    {_.flow(_.join(" › "), _.truncate({ length: 90 }))(path)}{" "}
  </span>
);

const path = {
  name: "path",
  fieldDecorator: pathDecorator,
  sort: { name: "path_sort" }
};

const descriptionsColumn = {
  name: "description",
  fieldSelector: _.path("description"),
  width: 2
};

const aliasSelector = ({ note }) => (_.isEmpty(note?.alias) ? "" : note.alias);

const aliasColumn = {
  name: "alias",
  fieldSelector: aliasSelector,
  width: 2
};

const name_column = {
  name: "name",
  sort: { name: "name.sort" },
  width: 2
};

const initialState = [
  name_column,
  descriptionsColumn,
  aliasColumn,
  ...defaultColumnsWithoutGroupAndName,
  path
];

export const structuresColumns = (state = initialState) => state;

import _ from "lodash/fp";
import { defaultStructureFieldColumns } from "@truedat/dd/selectors";

const defaultImplementationColumnsWithoutFields = _.filter(
  ({ name }) =>
    !_.includes(name)(["note", "metadata.nullable", "metadata.precision"])
)(defaultStructureFieldColumns);

export const structureFieldsColumns = (
  state = defaultImplementationColumnsWithoutFields
) => state;

import { toggleSidebar, hideSidebar } from "@truedat/core/routines";

export const sidebarVisible = (state = false, { type }) => {
  switch (type) {
    case hideSidebar.TRIGGER:
      return false;
    case toggleSidebar.TRIGGER:
      return !state;
    default:
      return state;
  }
};

import React, { Suspense } from "react";
import { Container } from "semantic-ui-react";
import { Alert, Loading, RouteListener } from "@truedat/core/components";
import Routes from "components/Routes";
import _dl from "styles/dl.less";
import _filters from "styles/filters.less";
import _graph from "styles/relationGraph.less";
import _subscriptions from "styles/subscriptions.less";
import _notifications from "styles/notifications.less";
import TopMenu from "./TopMenu";
import SideMenu from "./SideMenu";

export const Navigation = () => (
  <>
    <TopMenu />
    <SideMenu>
      <Container fluid>
        <Alert />
        <RouteListener />
        <Suspense fallback={<Loading />}>
          <Routes />
        </Suspense>
      </Container>
    </SideMenu>
  </>
);

export default Navigation;

import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Menu, Sidebar } from "semantic-ui-react";
import {
  GlossaryMenu,
  LineageMenu,
  GrantMenu,
  IngestMenu,
  MembersMenu,
  SearchMenu,
  SidebarToggle,
  TaxonomyMenu,
  QualityMenu,
  CatalogMenu
} from "@truedat/core/components";
import DashboardMenu from "./DashboardMenu.js";
import AdminMenu from "./AdminMenu.js";

export const SideMenu = ({ children, animation = "push", inverted }) => {
  const sidebarVisible = useSelector(state => state.sidebarVisible);

  const width = sidebarVisible ? null : "very thin";

  return (
    <Sidebar.Pushable>
      <Sidebar
        as={Menu}
        width={width}
        vertical
        visible
        inverted={inverted}
        icon={!sidebarVisible}
        animation={animation}
      >
        <SearchMenu />
        <GlossaryMenu />
        <CatalogMenu />
        <GrantMenu />
        <QualityMenu />
        <LineageMenu />
        <IngestMenu />
        <DashboardMenu />
        <TaxonomyMenu />
        <MembersMenu />
        <AdminMenu />
        <SidebarToggle animation={animation} />
      </Sidebar>
      <Sidebar.Pusher content={children} />
    </Sidebar.Pushable>
  );
};

SideMenu.propTypes = {
  animation: PropTypes.string,
  children: PropTypes.node,
  inverted: PropTypes.bool
};

export default SideMenu;

import React from "react";
import { useIntl } from "react-intl";

export default function SearchHomeAddon() {
  const { formatMessage } = useIntl();

  return (
    <>
      <div
        style={{
          display: "flex",
          "justify-content": "space-around",
          "flex-wrap": "wrap",
          padding: "20px"
        }}
      >
        <div
          className="box"
          style={{
            background: "#ffffff",
            margin: "10px",
            padding: "20px",
            width: "calc(25% - 20px)",
            "box-shadow": "0 5px 15px rgba(0,0,0,0.1)",
            "border-radius": "8px",
            transition: "transform 0.3s ease",
            position: "relative",
            "text-align": "center",
            "max-width":"21pc"
            
          }}
        >
          <a
            href="/concepts"
            style={{
              "text-decoration": "none",
              color: "inherit"
            }}
          >
            <i
              className="icon tags large"
              style={{
                display: "inline-block",
                "font-size": "3em",
                color: "#00A59F",
                "margin": "10px 0"
              }}
            ></i>
            <h2
              style={{
                color: "#6C6F70",
                "font-size": "1.2em"
              }}
            >
              {formatMessage({ id: "search.glossary" })}
            </h2>
            <p
              style={{
                color: "#666",
                "font-size": "0.9em"
              }}
            >
              {formatMessage({ id: "search.glossary_desc" })}
            </p>
          </a>
        </div>

        <div
          className="box"
          style={{
            background: "#ffffff",
            margin: "10px",
            padding: "20px",
            width: "calc(25% - 20px)",
            "box-shadow": "0 5px 15px rgba(0,0,0,0.1)",
            "border-radius": "8px",
            transition: "transform 0.3s ease",
            position: "relative",
            "text-align": "center",
            "max-width":"21pc"
          }}
        >
          <a
            href="/structures"
            style={{
              "text-decoration": "none",
              color: "inherit"
            }}
          >
            <i
              className=" icon block layout large"
              style={{
                display: "inline-block",
                "font-size": "3em",
                color: "#00A59F",
                "margin": "10px 0",
                "max-width":"21pc"
              }}
            ></i>
            <h2
              style={{
                color: "#6C6F70",
                "font-size": "1.2em"
              }}
            >
              {formatMessage({ id: "search.data_catalog" })}
            </h2>
            <p
              style={{
                color: "#666",
                "font-size": "0.9em"
              }}
            >
              {formatMessage({ id: "search.data_catalog_desc" })}
            </p>
          </a>
        </div>

        <div
          className="box"
          style={{
            background: "#ffffff",
            margin: "10px",
            padding: "20px",
            width: "calc(25% - 20px)",
            "box-shadow": "0 5px 15px rgba(0,0,0,0.1)",
            "border-radius": "8px",
            transition: "transform 0.3s ease",
            position: "relative",
            "text-align": "center",
            "max-width":"21pc"
          }}
        >
          <a
            href="/structuresGrantRequests"
            style={{
              "text-decoration": "none",
              color: "inherit"
            }}
          >
            <i
              className="icon key large"
              style={{
                display: "inline-block",
                "font-size": "3em",
                color: "#00A59F",
                "margin": "10px 0"
              }}
            ></i>
            <h2
              style={{
                color: "#6C6F70",
                "font-size": "1.2em"
              }}
            >
              {formatMessage({ id: "search.grants" })}
            </h2>
            <p
              style={{
                color: "#666",
                "font-size": "0.9em"
              }}
            >
              {formatMessage({ id: "search.grants_desc" })}
            </p>
          </a>
        </div>

        <div
          className="box"
          style={{
            background: "#ffffff",
            margin: "10px",
            padding: "20px",
            width: "calc(25% - 20px)",
            "box-shadow": "0 5px 15px rgba(0,0,0,0.1)",
            "border-radius": "8px",
            transition: "transform 0.3s ease",
            position: "relative",
            "text-align": "center",
            "max-width":"21pc"
          }}
        >
          <a
            href="/rules"
            style={{
              "text-decoration": "none",
              color: "inherit"
            }}
          >
            <i
              className="icon check square large"
              style={{
                display: "inline-block",
                "font-size": "3em",
                color: "#00A59F",
                "margin": "10px 0"
              }}
            ></i>
            <h2
              style={{
                color: "#6C6F70",
                "font-size": "1.2em"
              }}
            >
              {formatMessage({ id: "search.data_quality" })}
            </h2>
            <p
              style={{
                color: "#666",
                "font-size": "0.9em"
              }}
            >
              {formatMessage({ id: "search.data_quality_desc" })}
            </p>
          </a>
        </div>
      </div>
    </>
  );
}

import React from "react";
import { useAuthorized } from "@truedat/core/hooks";
import {
  CONFIGURATIONS,
  HIERARCHIES,
  JOBS,
  SOURCES,
  RELATION_TAGS,
  TEMPLATES,
  SUBSCRIPTIONS,
  TASKS,
  I18N_MESSAGES
} from "@truedat/core/routes";
import { Submenu } from "@truedat/core/components";

const items = [
  { name: "templates", routes: [TEMPLATES] },
  { name: "hierarchies", routes: [HIERARCHIES] },
  { name: "relations", routes: [RELATION_TAGS] },
  { name: "subscriptions", routes: [SUBSCRIPTIONS] },
  { name: "sources", routes: [SOURCES] },
  { name: "jobs", routes: [JOBS] },
  { name: "configurations", routes: [CONFIGURATIONS] },
  { name: "i18nMessages", routes: [I18N_MESSAGES] },
  { name: "tasks", routes: [TASKS] }
];

export const AdminMenu = () => {
  const authorized = useAuthorized();
  return authorized ? (
    <Submenu items={items} icon="setting" name="admin" />
  ) : null;
};

export default AdminMenu;

import { create } from "lodash";
import _ from "lodash/fp";
import { createSelector } from "reselect";

// eslint-disable-next-line prettier/prettier
const configSelector = field =>
  _.find(
    _.conformsTo({
      external_id: _.eq("config_metabase"),
      content: _.conformsTo({
        [field]: _.isNumber
      })
    })
  );

export const getCatalogDashboardConfig = createSelector(
  _.propOr({}, "systemConfigurations"),
  configSelector("catalog_dashboard_id")
);

export const getGovernmentDashboardConfig = createSelector(
  _.propOr({}, "systemConfigurations"),
  configSelector("government_dashboard_id")
);

export const getBasicInfoCompletenessDashboardConfig = createSelector(
  _.propOr({}, "systemConfigurations"),
  configSelector("basic_info_completeness_dashboard_id")
);

export const getDatalakesTablesEvolutionDashboardConfig = createSelector(
  _.propOr({}, "systemConfigurations"),
  configSelector("datalake_tables_evolution_dashboard_id")
);

export const getTablesVolumetryDashboardConfig = createSelector(
  _.propOr({}, "systemConfigurations"),
  configSelector("tables_volumetry_dashboard_id")
);

export const getControlCatalogDashboardConfig = createSelector(
  _.propOr({}, "systemConfigurations"),
  configSelector("control_catalog_dashboard_id")
);

export const getControlGlossaryDashboardConfig = createSelector(
  _.propOr({}, "systemConfigurations"),
  configSelector("control_glossary_dashboard_id")
);

export const getTablesEvolutionDashboardConfig = createSelector(
  _.propOr({}, "systemConfigurations"),
  configSelector("tables_evolution_dashboard_id")
);

export const getManagementAndBehaviorUsersDashboardConfig = createSelector(
  _.propOr({}, "systemConfigurations"),
  configSelector("management_and_behavior_users_dashboard_id")
);

export default {
  "filters.agrupacion": "Agrupación información",
  "filters.calculan": "Calculado por",
  "filters.confidencialidad": "Confidencialidad",
  "filters.criticidad": "Criticidad",
  "filters.data_officer": "Data Officer",
  "filters.data_owner": "Data Owner",
  "filters.data_steward": "Data Steward",
  "filters.dominio_informacion": "Dominio Información de Gestión",
  "filters.fase1": "DWH Fase I",
  "filters.functional_owner": "Functional Owner",
  "filters.idioma": "Idioma",
  "filters.naturaleza": "Naturaleza del dato",
  "filters.pais": "País",
  "filters.sensible": "Sensible",
  "filters.sujeto_gdpr": "Sujeto a GDPR",
  "filters.temporalidad": "Temporalidad",
  "filters.tipo": "Tipo de término",
  "filters._confidential": "Confidencial",
  "filters.history": "Histórico",
  "filters.periodicity": "Periodicidad",
  "filters.scope": "Alcance",
  "filters.owner": "Data Owner",
  "filters.dq_periodicity": "Periodicidad",
  "filters.dq_principle": "Principio de calidad",
  "filters.class.raw": "Clase",
  "filters.class.raw.field": "Campo",
  "filters.with_content.raw": "Info Adicional",
  "filters.businesstermtype": "Tipo de Término de Negocio",
  "filters.criticalitylevel": "Nivel de Criticidad",
  "filters.datasteward": "Data Steward",
  "filters.datasponsor": "Data Sponsor",
  "filters.language": "Idioma",
  "filters.reportlevel": "Nivel de Reporte",

  //Notes personalization
  "structures.uploadStructures.failed.note.only_draft_are_editable":
    "La información adicional de la fila {row} debe estar en estado borrador para poder ser editada",
  "tabs.dd.notes": "Info Adicional",
  "structure.notes.create": "Crear Info Adicional",
  "structure.notes.edit": "Editar info adicional",
  "structure.notes.empty": "No hay información adicional",
  "structure.notes.actions.deleted.confirmation.header":
    "Eliminar info adicional",
  "structure.notes.actions.deleted.confirmation.content":
    "¿Estás seguro de que quieres eliminar esta info adicional?",
  "structure.notes.actions.deprecated.confirmation.header":
    "Archivar info adicional",
  "structure.notes.actions.deprecated.confirmation.content":
    "¿Estás seguro de que quieres archivar esta información adicional?",

  //Filters DD
  "filters.availability": "Disponibilidad",
  "filters.confidentiality": "Confidencialidad",
  "filters.kpi": "Key Performance Indicator (KPI)",
  "filters.layer": "Capa",
  "filters.gdpr": "GDPR",
  "filters.updatefrequency": "Frecuencia Actualización",
  "filters.updatetype": "Tipo de Actualización",
  "filters.business_area": "Área de Negocio",
  "filters.democratized": "Democratizado",
  "filters.historicaldepth": "Profundidad de Históricos",
  "filters.initiative": "Iniciativa",
  "filters.obsolescencia_analitica": "Obsolescencia Analítica",
  "filters.retention": "Retención",
  "filters.rowconfidentiality": "Confidencialidad de fila",

  //
  "navigation.glossary.concepts": "Glosario" /*"Glosario de negocio",*/,
  "navigation.glossary.taxonomy": "Taxonomía",
  "navigation.dictionary.structures": "Catálogo" /*"Catálogo de datos",*/,
  "navigation.quality.rules": "Calidad" /*"Calidad del dato",*/,
  "navigation.quality.dashboard": "Dashboard de Calidad",
  "navigation.lineage.search": "Linaje",
  "navigation.ingest.ingests": "Acuerdos de Interfaz",

  "login.form.actions.oidc.login": "Login Cepsa Química",
  "login.description": "Data Governance",

  "structure.lopd": "Sensibilidad GDPR",

  //Concepts

  "concepts.props.type_label": "Tipo",

  //Structure types

  "structure.type.Instance.text": "Instancia",
  "structure.type.Instance.icon": "server",

  "structure.type.Column.text": "Columna",
  "structure.type.Column.icon": "columns",

  "structure.type.Table.text": "Tabla",
  "structure.type.Table.icon": "table",

  "structure.type.table.text": "Tabla",
  "structure.type.table.icon": "table",

  "structure.type.Database.text": "Base de datos",
  "structure.type.Database.icon": "database",

  "structure.type.database.text": "Base de datos",
  "structure.type.database.icon": "database",

  "structure.type.Schema.text": "Esquema",
  "structure.type.Schema.icon": "database",

  "structure.type.Report.text": "Reporte",
  "structure.type.Report.icon": "chart bar",
  "structure.type.Report.lineage.icon": "chart-bar",

  "structure.type.Carpeta.text": "Carpeta",
  "structure.type.Carpeta.icon": "folder",

  "structure.type.Folder.text": "Carpeta",
  "structure.type.Folder.icon": "folder",

  "structure.type.Documento.text": "Documento",
  "structure.type.Documento.icon": "file outline",
  "structure.type.Documento.lineage.icon": "file",

  "structure.type.Informe.text": "Informe",
  "structure.type.Informe.icon": "chart bar",
  "structure.type.Informe.lineage.icon": "chart-bar",

  "structure.type.Field.text": "Campo",
  "structure.type.Field.icon": "columns",

  "structure.type.BASE TABLE.text": "Tabla base",
  "structure.type.BASE TABLE.icon": "table",

  "structure.type.DssTypeDocumentDefinition.text":
    "Definición de tipo de documento Dss",
  "structure.type.DssTypeDocumentDefinition.icon": "file outline",
  "structure.type.DssTypeDocumentDefinition.lineage.icon": "file",

  "structure.type.DssTypeReportDefinition.text":
    "Definición de tipo de informe Dss",
  "structure.type.DssTypeReportDefinition.icon": "chart bar",
  "structure.type.DssTypeReportDefinition.lineage.icon": "chart-bar",

  "structure.type.DssTypeFolder.text": "Carpeta de tipo de Dss",
  "structure.type.DssTypeFolder.icon": "folder",

  "structure.type.Calculated Table.text": "Tabla calculada",
  "structure.type.Calculated Table.icon": "table",

  "structure.type.USER_TABLE.text": "Tabla de usuario",
  "structure.type.USER_TABLE.icon": "table",

  "structure.type.VIEW.text": "Vista",
  "structure.type.VIEW.icon": "table",

  "structure.type.DIRECTORY.text": "Directorio",
  "structure.type.DIRECTORY.icon": "folder",

  "structure.type.FILE.text": "Fichero",
  "structure.type.FILE.icon": "file outline",
  "structure.type.FILE.lineage.icon": "file",

  "structure.type.text.text": "Columna",
  "structure.type.text.icon": "font",

  "structure.type.EXTERNAL_TABLE.text": "Tabla externa",
  "structure.type.EXTERNAL_TABLE.icon": "table",

  // MYSQL
  "structure.type.varchar.text": "Columna",
  "structure.type.varchar.icon": "font",

  "structure.type.datetime.text": "Columna",
  "structure.type.datetime.icon": "clock",

  "structure.type.decimal.text": "Columna",
  "structure.type.decimal.icon": "hashtag",

  "structure.type.tinyint.text": "Columna",
  "structure.type.tinyint.icon": "hashtag",

  //Postgres
  "structure.type.text.text": "Column",
  "structure.type.text.icon": "font",

  "structure.type.timestamp without time zone.text": "Column",
  "structure.type.timestamp without time zone.icon": "clock outline",

  "structure.type.character varying.text": "Column",
  "structure.type.character varying.icon": "font",

  "structure.type.character.text": "Column",
  "structure.type.character.icon": "font",

  "structure.type.name.text": "Column",
  "structure.type.name.icon": "font",

  "structure.type.integer.text": "Column",
  "structure.type.integer.icon": "hashtag",

  "structure.type.double precision.text": "Column",
  "structure.type.double precision.icon": "hashtag",

  "structure.type.numeric.text": "Column",
  "structure.type.numeric.icon": "hashtag",

  "structure.type.jsonb.text": "Column",
  "structure.type.jsonb.icon": "file code outline",

  "structures.actions.editableDownload.tooltip":
    "Descarga de información adicional editable de estructuras",
  "structures.actions.upload.confirmation.header":
    "Subir fichero de información adicional de estructuras",

  "quality.result_avg": "Calidad actual (%)",
  "quality.last_execution_at": "Última ejecución",

  //Business concepts relations
  "source.bc_father_of": "Concepto padre de",
  "target.bc_father_of": "Concepto hijo de",
  "source.bc_related_to": "Relacionado con",
  "target.bc_related_to": "Relacionado con",
  "source.bc_translated_to_spanish": "Se traduce a español como",
  "target.bc_translated_to_spanish": "Se traduce a inglés como",
  "source.bc_parent": "Concepto padre de",
  "target.bc_parent": "Concepto hijo de",

  //Parámetros de implementaciones
  "ruleImplementation.props.campo": "Campo",
  "ruleImplementation.props.campo.placeholder": "Selecciona el campo",

  "ruleImplementation.props.campo_maestro": "Campo maestro",
  "ruleImplementation.props.campo_maestro.placeholder":
    "Selecciona el campo maestro",

  "appSetup.welcome.message": "Bienvenido a Truedat",
  "appSetup.configuration.message":
    "Parece que todo se ha instalado correctamente. Vamos a comenzar a configurar la aplicación para poder empezar a usarla tras unos sencillos pasos",
  "appSetup.begin.button": "Comenzar",

  // Ingests
  "alert.ingestAction.failed.header": "Error creando acuerdo de interfaz",
  "alert.upload.failed.content":
    "Error al leer el Acuerdo de Interfaz, no se ha realizado ninguna inserción. Error en el Acuerdo de Interfaz [{row}], {error}",
  "alert.upload.success.content":
    "Se han insertado correctamente {count} Acuerdos de Interfaz",
  "ingest.error.existing.ingest":
    "({text}) Ya existe un Acuerdo de Interfaz con el nombre indicado",
  "ingest.events.add_resource_field": "asoció un dato al Acuerdo de Interfaz",
  "ingest.events.delete_resource_field":
    "desasoció un dato del Acuerdo de Interfaz",
  "ingest.events.ingest_deprecated": "Archivó el Acuerdo de Interfaz",
  "ingest.relatedTo.ingest": "Relacionado con Acuerdo de Interfaz",
  "ingestLink.actions.delete.confirmation.content":
    "Se eliminará la vinculación entre el Acuerdo de Interfaz y la estructura. ¿Estás seguro?",
  "ingestRelations.relatedDataIngest": "Acuerdos de Interfaz relacionadas",
  "ingests.actions.create": "Nuevo Acuerdo de Interfaz",
  "ingests.actions.delete.confirmation.content":
    "Se eliminará esta Acuerdo de Interfaz. ¿Estás seguro?",
  "ingests.actions.delete.confirmation.header": "Borrar Acuerdo de Interfaz",
  "ingests.actions.deprecate.confirmation.content":
    "Se archivará este Acuerdo de Interfaz. ¿Estás seguro?",
  "ingests.actions.deprecate.confirmation.header":
    "Archivar Acuerdo de Interfaz",
  "ingests.actions.upload.confirmation.header":
    "Subir fichero Acuerdos de Interfaz",
  "ingests.crumbs.top": "Acuerdos de Interfaz",
  "ingests.header": "Acuerdos de Interfaz",
  "ingests.header.duplicate": "Duplicar Acuerdo de Interfaz",
  "ingests.header.edit": "Editar Acuerdo de Interfaz",
  "ingests.props.ingest_id": "Id Acuerdo de Interfaz",
  "ingests.props.name": "Acuerdo de Interfaz",
  "ingests.search.placeholder": "Buscar Acuerdos de Interfaz...",
  "ingests.search.results.empty":
    "No se han encontrado Acuerdos de Interfaz para esta búsqueda",
  "ingests.subheader": "Consultar y gestionar Acuerdos de Interfaz",
  "navigation.ingest": "Acuerdo de Interfaz",
  "navigation.ingests": "Acuerdos de Interfaz",
  "parentIngest.selector.label": "Acuerdo de Interfaz padre",
  "parentIngest.selector.placeholder": "Selecciona un Acuerdo de Interfaz...",
  "tabs.ie.ingest": "Acuerdo de Interfaz",
  "tabs.ie.relationsIngests": "Acuerdos de Interfaz relacionadas",
  "tabs.se.ingests": "Acuerdos de Interfaz",
  "ingest.relations.actions.data_structure.delete.confirmation.content":
    "Se eliminará la vinculación entre el Acuerdo de Interfaz y la estructura. ¿Estás seguro?",
  "filters.tipo_ingesta": "Tipo Acuerdo",
  "ingests.tabs.published": "Publicadas",
  "ingest.events.relation_created": "Nueva relación",
  "ingest.events.relation_deleted": "Relación eliminada",

  //SIDEMENU
  "sidemenu.catalog_dashboard": "Catálogo",
  "sidemenu.government_dashboard": "Gobierno",
  "sidemenu.basic_info_completeness_dashboard":
    "Completitud de Información Básica",
  "sidemenu.datalakes_tables_evolution_dashboard":
    "Evolución de Tablas en Datalakes",
  "sidemenu.tables_volumetry_dashboard": "Volumetría de Tablas",
  "templates.tabs.gr": "Grants",
  "sidemenu.ingests": "Acuerdos de Interfaz",
  "sidemenu.relations": "Relaciones",

  //Relations Structures
  "source.Contiene": "Contiene",
  "target.Contiene": "Es contenido por",
  "target.Se calcula en base a": "Se calcula en base a",
  "source.Se calcula en base a": "Es usado para calcular",
  "target.Se compone de": "Se compone de",
  "source.Se compone de": "Usado para componer",
  "source.Se construye con el informe": "Se construye con el informe",
  "target.Se construye con el informe": "Sirve para construir",
  "source.Relacionado con": "Relacionado con",
  "target.Relacionado con": "Relacionado con",

  //Structures columns personalization
  "structure.alias": "Alias",

  "filtersGrid.field.modifier.cast_as_timestamp": "Convertir a timestamp",
  "filtersGrid.field.modifier.cast_as_date": "Formato de fecha",
  "filtersGrid.field.modifier.to_string": "Convertir a texto",
  "filtersGrid.field.modifier.substring": "Subcadena",
  "filtersGrid.field.modifier.substring.start": "Posición de inicio",
  "filtersGrid.field.modifier.substring.end": "Posición de fin",
  "filtersGrid.field.modifier.concat": "Concatenar",
  "filtersGrid.field.modifier.concat.with_fields": "Campos"
};

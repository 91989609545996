import React from "react";
import _ from "lodash/fp";
import { defaultStructureProperties } from "@truedat/dd/selectors";

const defaultStructuresPropertiesWithoutGroup = _.filter(
  _.negate(_.propEq("name", "group"))
)(defaultStructureProperties);

const businessAreaSelector = ({ note }) => {
  if (note?.business_area) {
    return note.business_area.replace("group:", "").replace("user:", "");
  }
};

const initiativeSelector = ({ note, metadata }) =>
  _.isEmpty(note?.initiative) ? metadata.initiativename : note.initiative;

const initialState = [
  ...defaultStructuresPropertiesWithoutGroup,
  {
    name: "Initiative",
    fieldSelector: initiativeSelector
  },
  {
    name: "Business Area",
    fieldSelector: businessAreaSelector
  },
  {
    name: "Data Steward",
    fieldSelector: _.path("note.datasteward")
  },
  {
    name: "Democratized",
    fieldSelector: _.path("note.democratized")
  }
];

export const structureProperties = (state = initialState) => state;

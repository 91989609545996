/* eslint-disable no-undef */
import _ from "lodash/fp";
import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { useAuthorized } from "@truedat/core/hooks";
import { getQualityDashboardConfig } from "@truedat/core/selectors";
import { DomainsLoader } from "@truedat/bg/taxonomy/components";
import { ConfigurationTokenLoader } from "@truedat/cx/components";
import routes from "../../routes";

import {
  getBasicInfoCompletenessDashboardConfig,
  getDatalakesTablesEvolutionDashboardConfig,
  getTablesVolumetryDashboardConfig,
  getGovernmentDashboardConfig,
  getControlCatalogDashboardConfig,
  getControlGlossaryDashboardConfig,
  getTablesEvolutionDashboardConfig,
  getManagementAndBehaviorUsersDashboardConfig
} from "../../selectors/getDashboardConfigs";
import DashboardMetabase from "../DashboardMetabase";

const qualityDashboardPermissions = ["view_quality_rule"];
const mainDashboardPermissions = ["view_dashboard"];

const DashboardRoutes = () => {
  const authorized_dashboards = useAuthorized("dashboards");
  const authorized_dq = useAuthorized("data_quality");
  const authorized = authorized_dashboards || authorized_dq;

  const qualityDashboardConfig = useSelector(getQualityDashboardConfig);

  const governmentDashboardConfig = useSelector(getGovernmentDashboardConfig);

  const tablesVolumetryDashboardConfig = useSelector(
    getTablesVolumetryDashboardConfig
  );
  const controlCatalogDashboardConfig = useSelector(
    getControlCatalogDashboardConfig
  );
  const controlGlossaryDashboardConfig = useSelector(
    getControlGlossaryDashboardConfig
  );
  const tablesEvolutionDashboardConfig = useSelector(
    getTablesEvolutionDashboardConfig
  );
  const managementAndBehaviorUsersDashboardConfig = useSelector(
    getManagementAndBehaviorUsersDashboardConfig
  );
  return authorized ? (
    <>
      {authorized_dq && !_.isNil(qualityDashboardConfig) && (
        <Route
          exact
          path={routes.DQ_DASHBOARD}
          render={() => (
            <>
              <ConfigurationTokenLoader
                externalId={qualityDashboardConfig.external_id}
                dashboardId={
                  qualityDashboardConfig.content.quality_dashboard_id
                }
              />
              <DomainsLoader actions={qualityDashboardPermissions} />
              <DashboardMetabase dashboardConfig={qualityDashboardConfig} />
            </>
          )}
        />
      )}
      {authorized_dashboards && !_.isNil(governmentDashboardConfig) && (
        <Route
          exact
          path={routes.GOVERNMENT_DASHBOARD}
          render={() => (
            <>
              <ConfigurationTokenLoader
                externalId={governmentDashboardConfig.external_id}
                dashboardId={
                  governmentDashboardConfig.content.government_dashboard_id
                }
              />
              <DomainsLoader actions={mainDashboardPermissions} />
              <DashboardMetabase dashboardConfig={governmentDashboardConfig} />
            </>
          )}
        />
      )}
      {authorized_dashboards && !_.isNil(controlCatalogDashboardConfig) && (
        <Route
          exact
          path={routes.CONTROL_CATALOG_DASHBOARD}
          render={() => (
            <>
              <ConfigurationTokenLoader
                externalId={controlCatalogDashboardConfig.external_id}
                dashboardId={
                  controlCatalogDashboardConfig.content
                    .control_catalog_dashboard_id
                }
              />
              <DomainsLoader actions={mainDashboardPermissions} />
              <DashboardMetabase
                dashboardConfig={controlCatalogDashboardConfig}
              />
            </>
          )}
        />
      )}
      {authorized_dashboards && !_.isNil(controlGlossaryDashboardConfig) && (
        <Route
          exact
          path={routes.CONTROL_GLOSSARY_DASHBOARD}
          render={() => (
            <>
              <ConfigurationTokenLoader
                externalId={controlGlossaryDashboardConfig.external_id}
                dashboardId={
                  controlGlossaryDashboardConfig.content
                    .control_glossary_dashboard_id
                }
              />
              <DomainsLoader actions={mainDashboardPermissions} />
              <DashboardMetabase
                dashboardConfig={controlGlossaryDashboardConfig}
              />
            </>
          )}
        />
      )}
      {authorized_dashboards && !_.isNil(tablesEvolutionDashboardConfig) && (
        <Route
          exact
          path={routes.TABLES_EVOLUTION_DASHBOARD}
          render={() => (
            <>
              <ConfigurationTokenLoader
                externalId={tablesEvolutionDashboardConfig.external_id}
                dashboardId={
                  tablesEvolutionDashboardConfig.content
                    .tables_evolution_dashboard_id
                }
              />
              <DomainsLoader actions={mainDashboardPermissions} />
              <DashboardMetabase
                dashboardConfig={tablesEvolutionDashboardConfig}
              />
            </>
          )}
        />
      )}
      {authorized_dashboards && !_.isNil(tablesVolumetryDashboardConfig) && (
        <Route
          exact
          path={routes.TABLES_VOLUMETRY_DASHBOARD}
          render={() => (
            <>
              <ConfigurationTokenLoader
                externalId={tablesVolumetryDashboardConfig.external_id}
                dashboardId={
                  tablesVolumetryDashboardConfig.content
                    .tables_volumetry_dashboard_id
                }
              />
              <DomainsLoader actions={mainDashboardPermissions} />
              <DashboardMetabase
                dashboardConfig={tablesVolumetryDashboardConfig}
              />
            </>
          )}
        />
      )}
      {authorized_dashboards &&
        !_.isNil(managementAndBehaviorUsersDashboardConfig) && (
          <Route
            exact
            path={routes.MANAGEMENT_BEHAVIOR_USERS_DASHBOARD}
            render={() => (
              <>
                <ConfigurationTokenLoader
                  externalId={
                    managementAndBehaviorUsersDashboardConfig.external_id
                  }
                  dashboardId={
                    managementAndBehaviorUsersDashboardConfig.content
                      .management_and_behavior_users_dashboard_id
                  }
                />
                <DomainsLoader actions={mainDashboardPermissions} />
                <DashboardMetabase
                  dashboardConfig={managementAndBehaviorUsersDashboardConfig}
                />
              </>
            )}
          />
        )}
    </>
  ) : null;
};

export default DashboardRoutes;

import _ from "lodash/fp";
import React from "react";
import { useAuthorized } from "@truedat/core/hooks";
import { Submenu } from "@truedat/core/components";

import routes from "../../routes";

const items = [
  {
    name: "control_catalog_dashboard",
    routes: [routes.CONTROL_CATALOG_DASHBOARD]
  },
  {
    name: "control_glossary_dashboard",
    routes: [routes.CONTROL_GLOSSARY_DASHBOARD]
  },
  {
    name: "tables_volumetry_dashboard",
    routes: [routes.TABLES_VOLUMETRY_DASHBOARD]
  },
  {
    name: "tables_evolution_dashboard",
    routes: [routes.TABLES_EVOLUTION_DASHBOARD]
  },
  {
    name: "management_behavior_users_dashboard",
    routes: [routes.MANAGEMENT_BEHAVIOR_USERS_DASHBOARD]
  },
  {
    name: "government_dashboard",
    routes: [routes.GOVERNMENT_DASHBOARD]
  },
  {
    name: "quality_dashboard",
    routes: [routes.DQ_DASHBOARD]
  }
];

export const DashboardMenu = () => {
  const authorized = useAuthorized("dashboards");
  return authorized ? (
    <Submenu items={items} icon="chart bar" name="Dashboards" />
  ) : null;
};

export default DashboardMenu;
